import 'datatables.net-bs4/css/dataTables.bootstrap4.css';

jQuery(document).on('turbolinks:load', function () {
  $('#machines-datatable').dataTable({
    "language": {
      "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Swedish.json"
    },
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#machines-datatable').data('source')
    },
    "stateSave": true,
    "pagingType": "full_numbers",
    "iDisplayLength": 100,
    "columns": [
      { "data": "id" },
      { "data": "name" },
      { "data": "smart_dok_id" },
      { "data": "edit", "orderable": false }
    ]
  });
});
