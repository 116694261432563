$(document).on('turbolinks:load', function () {
  $( '.select-all input' ).click( function () {
    $( '.list-group-item input[type="checkbox"]' ).prop('checked', this.checked);
  });

  $( '.list-group-item input[type="checkbox"]' ).click( function () {
    $( '.list-group-item input[type="checkbox"]' ).each(function () {
      if(!this.checked) {
        $( '.select-all input' ).prop('checked', false);
        return false;
      } else {
        $( '.select-all input' ).prop('checked', true);
      }
    });
  });

  $( '.project-filter' ).on('input', function () {
    let input = this.value;
    $( '.smartdok label' ).each(function () {
      if(this.innerHTML.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
        $(this).parent().show();
      } else {
        $(this).parent().hide();
      }
    });
  });
});
