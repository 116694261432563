import 'js-autocomplete/auto-complete.css';
import autocomplete from 'js-autocomplete';

const autocompleteSearch = function() {
  document.addEventListener("turbolinks:load", () => {
    if (searchInput()) {
      document.getElementById('project_name').addEventListener('click', () => {
        setUpAutoComplete();
      });
    }
  });
};

const setUpAutoComplete = function () {
  if (projects() && searchInput()) {
    new autocomplete({
      selector: searchInput(),
      minChars: 1,
      source: autocompleteSource
    });
  }
}

const autocompleteSource = function (term, suggest) {
  term = term.toLowerCase();
  const choices = projects()
  const matches = [];

  choices.forEach((project) => {
    if (~project.toLowerCase().indexOf(term)) {
      matches.push(project);
    }
  });
  suggest(matches);
}

const projects = function () {
  return JSON.parse(document.getElementById('search-data').dataset.projects)
}

const searchInput = function () {
  return document.getElementById('project_name');
}

export { autocompleteSearch };
