import 'datatables.net-bs4/css/dataTables.bootstrap4.css';

jQuery(document).on('turbolinks:load', function () {
  $('#hogia-import-files-datatable').dataTable({
    "language": {
      "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Swedish.json"
    },
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#hogia-import-files-datatable').data('source')
    },
    "stateSave": true,
    "pagingType": "full_numbers",
    "iDisplayLength": 25,
    "columns": [
      { "data": "id" },
      { "data": "from" },
      { "data": "to" },
      { "data": "projects", "orderable": false },
      { "data": "download", "orderable": false },
      { "data": "remove", "orderable": false }
    ]
  });
});
